<template>
    <div class="postStandard">
        <div class="container flex" >
            <div class="tree">
                <div class="tree-name">职位管理</div>
                <a-tree :selected-keys="selectedKeys" :tree-data="treeData" :replace-fields="treeReplaceFields" @select="_treeSelect"/>
            </div>
            <div class="content">
                <div class="table">
                    <div class="flex" style="padding-bottom: 10px;">
                        <div class="search-div">
                            <a-tag closable @close="_tabClose(index)" v-for="(item,index) in searchList" :key="item.name" style="margin-bottom: 5px;padding: 3px 5px">
                                <span>{{item.name}}</span>：
                                <span>{{item.value}}</span>
                            </a-tag>
                        </div>
                        <a-button type="primary" @click="_addModel()">添加</a-button>
                    </div>
                    <a-table :columns="tableColumns" :data-source="tableData" :rowKey="'Id'" :customRow="_clickRow">
                        <div slot="natureTitle">
                            <span>职位性质 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('select',$event,PositionNatureList,'职位性质')"></i></span>
                        </div>

                        <div slot="experienceTitle">
                            <span>工作经验 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('select',$event,WorkExperienceList,'工作经验')"></i></span>
                        </div>
                        <div slot="operation" slot-scope="text, record,index" style="color: #2994FF">
                            <span class="iconfont tab-icon icon-zhongmingming-" style="margin-right: 8px;" @click="_setData(index)"></span>
                            <a-popconfirm title="你确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="_del(index)">
                                <span class="iconfont tab-icon icon-shanchu-"></span>
                            </a-popconfirm>
                        </div>
                    </a-table>
                </div>
            </div>
        </div>
        <tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>
        <a-drawer title="添加职位" placement="right" :closable="false" :width="isPad?800:1100" :visible="addVisible" @close="_cancelVisible">
            <div style="padding-bottom: 60px">
                <div class="flex">
                    <div class="draw-content" style="padding-right: 15px;">
                        <div class="draw-row">
                            <div class="flex">
                                <div style="flex:1;margin-right: 5px">
                                    <div class="text">职位名称<span>*</span></div>
                                    <div class="input-position" style="display: block">
                                        <a-select style="width: 100%" v-model="PositionName">
                                            <a-select-option :value="index" v-for="(item,index) in baseList.position_list" :key="item.PositionName">{{item.PositionName}}</a-select-option>
                                        </a-select>
                                    </div>
                                </div>

                                <div style="flex:1;">
                                    <div class="text">职务名称</div>
                                    <div class="input-position" style="display: block">
                                        <a-select style="width: 100%" v-model="PositionRoleName">
                                            <a-select-option  value="">请选择</a-select-option>
                                            <a-select-option :value="item.PositionName" v-for="(item,index) in PositionName?baseList.position_list[PositionName].Children:[]" :key="item.PositionName">{{item.PositionName}}</a-select-option>
                                        </a-select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="draw-row">
                            <div class="text">所属职级<span>*</span></div>
                            <div class="input">
                                <a-input placeholder="请输入" disabled :value="PositionName?baseList.position_list[PositionName].Level:''"></a-input>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">薪酬范围</div>
                            <div class="input money-scope">
                                <a-input v-model="SalaryMin"></a-input> <span style="margin: 4px 8px 0;">~</span> <a-input v-model="SalaryMax"></a-input>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">学历要求</div>
                            <div class="input">
                                <a-select style="width: 100%" v-model="Education">
                                    <a-select-option :value="item" v-for="item in baseList.Education" :key="item">{{item}}</a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">缺岗城市</div>
                            <div class="input">
                                <a-select mode="multiple" style="width: 100%" @change="_selectCity">
                                    <a-select-option :value="item" v-for="item in baseList.city_list" :key="item">{{item}}</a-select-option>
                                </a-select>
                            </div>
                        </div>
                    </div>
                    <div class="draw-content" style="padding-left: 15px;">
                        <div class="draw-row">
                            <div class="text">职位性质<span>*</span></div>
                            <div class="input nature-list">
                                <a-radio-group button-style="solid" v-model="PositionNature">
                                    <a-radio-button :value="item.value" v-for="item in PositionNatureList" :key="item.name">{{item.name}}</a-radio-button>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">工作经验<span>*</span></div>
                            <div class="input">
                                <a-select style="width: 100%" v-model="WorkExperience">
                                    <a-select-option :value="item.value" v-for="item in WorkExperienceList" :key="item.name">{{item.name}}</a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">职能类型</div>
                            <div class="input">
                                <a-select style="width: 100%" v-model="PositionType">
                                    <a-select-option :value="item.title" v-for="item in baseList.cate_list" :key="item.title">{{item.title}}</a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">招聘人数</div>
                            <div class="input">
                                <a-input placeholder="请输入" v-model="Num"></a-input>
                            </div>
                        </div>
                        <div class="draw-row">
                            <div class="text">创建人</div>
                            <div class="input">
                                <a-input placeholder="请输入" disabled v-model="__USER__.name"></a-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="draw-row">
                    <div class="text">职位描述</div>
                    <div class="input" style="height: 250px;">
                        <div id="websiteEditorElem" style="width:100%;height:100%"></div>
                    </div>
                </div>

                <div class="draw-row">
                    <div class="text">备注</div>
                    <div class="input" style="height: 70px">
                        <a-textarea placeholder="备注" v-model="Remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
                    </div>
                </div>

                <div class="draw-title">展示位置</div>
                <div class="show-location">
                    <div class="row">
                        <div>
                            <span class="label-name">招聘公众号</span>
                            <a-switch v-model="ShowOnWechat"/>
                        </div>
                        <div>
                            <span class="label-name">公司官网</span>
                            <a-switch v-model="ShowOnWeb"/>
                        </div>
                    </div>
                </div>

                <div class="show-location">
                    <div class="row">
                        <div>
                            <span class="label-name">内推渠道</span>
                            <a-switch v-model="ShowOnInterpolation"/>
                        </div>
                        <div style="width: 600px;">
                            <span class="label-name" style="width: 100px">内推奖励规则</span>
                            <div style="width: 400px;">
                                <a-select style="width: 200px" v-model="InterpolationRuleId">
                                    <a-select-option :value="item.Id" v-for="(item,index) in baseList.rule_list" :key="item.Id">{{item.Name}}</a-select-option>
                                </a-select>
                                <span class="add-award-btn" @click="addRuleVisible = true"><a-icon type="plus" /> 新增奖励规则</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_cancelVisible()">取消</a-button>
                <a-button type="primary"  @click="_addSave()">确定</a-button>
            </div>
            <a-drawer title="新增职级奖励规则" placement="right" :closable="false" width="500" :visible="addRuleVisible" @close="_closeVisible()">
                <div class="add-rule-content">
                    <div class="row">
                        <div class="row-label">关联职级</div>
                        <div class="row-input">
                            <a-select style="width: 100%" v-model="Position">
                                <a-select-option :value="index" v-for="(item,index) in selectData" :key="item.PositionName">{{item.PositionName}}</a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="row-label">入职奖励</div>
                        <div class="row-input">
                            <a-input placeholder="请输入数字" v-model="EntryPrize"></a-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="row-label">转正奖励</div>
                        <div class="row-input">
                            <a-input placeholder="请输入数字" v-model="FullPrize"></a-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="row-label">规则说明</div>
                        <div class="row-input">
                            <a-textarea v-model="Rule"></a-textarea>
                        </div>
                    </div>
                </div>
                <div class="alert-footer">
                    <a-button style="margin-right: 25px" @click="_closeVisible()">取消</a-button>
                    <a-button type="primary"  @click="_saveAdd()">确定</a-button>
                </div>
            </a-drawer>
        </a-drawer>
    </div>
</template>

<script>
    import tableHeader from '../../components/tableHeader/filterBox';
    import E from 'wangeditor'
    export default {
        name: "postStandard",
        components:{
            tableHeader,E
        },
        data(){
            return {
                selectedKeys: [],
                treeData: [],
                treeReplaceFields:{
                    key: 'value'
                },
                isPad: false,
                addVisible: false,
                addRuleVisible: false,
                tableData: [],
                tableColumns: [
                    {
                        title: '岗位',
                        dataIndex: 'PositionName',
                        align: 'center',
                    },
                    {
                        title: '职务',
                        dataIndex: 'PositionRoleName',
                        align: 'center',
                    },
                    {
                        title: '所属职级',
                        dataIndex: 'PositionLevel',
                        align: 'center',
                    },
                    {
                        dataIndex: 'PositionNature',
                        slots: { title: 'natureTitle' },
                        align: 'center',
                    },
                    {
                        dataIndex: 'WorkExperience',
                        slots: { title: 'experienceTitle' },
                        align: 'center',
                    },
                    {
                        title: '薪酬范围',
                        dataIndex: 'SalaryRange',
                        align: 'center',
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateUserName',
                        align: 'center',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'CreateTime',
                        align: 'center',
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                tabs: 0,
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                PositionNatureList: [
                    {
                        name: '全职',
                        value: '全职'
                    },
                    {
                        name: '兼职',
                        value: '兼职'
                    },
                    {
                        name: '实习',
                        value: '实习'
                    },
                    {
                        name: '其他',
                        value: '其他'
                    },
                ],
                WorkExperienceList: [],
                searchList: [],
                phoneEditor: '',
                baseList: '',

                setId: '',
                PositionName: '',
				PositionRoleName: '',
                PositionNature: '',
                PositionType: '',
                Education: '',
                WorkExperience: '',
                City: '',
                Description: '',
                Remark: '',
                ShowOnWechat: false,
                ShowOnWeb: false,
                ShowOnInterpolation: false,
                InterpolationRuleId: '',
                Num: '',
                SalaryMin: '',
                SalaryMax: '',

                Position: '',
                EntryPrize: '',
                FullPrize: '',
                Rule: '',
                selectData: []
            }
        },
        created(){
            this._treeData();
            this._infoData();
            this._baseInfo();
            this.$axios.get(8000074,{},res=>{
                this.selectData = res.data.data;
            });
            if(document.documentElement.clientWidth < 1400){
                this.isPad = true;
            }else{
                this.isPad = false;
            }
        },
        methods: {
            _saveAdd(){
                this.$axios.post(8000075,{
                    Id: '',
                    PositionId: this.selectData[this.Position].Id,
                    PositionName: this.selectData[this.Position].PositionName,
                    EntryPrize: this.EntryPrize,
                    FullPrize: this.FullPrize,
                    Rule: this.Rule
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        this._baseInfo();
                        this._closeVisible();
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _baseInfo(){
                this.$axios.get(8000067,{},res=>{
                    if(res.data.code==1){
                        this.baseList = res.data.data;
                        //WorkExperienceList
                        this.baseList.Experience.map(res=>{
                            this.WorkExperienceList.push({name: res,value: res})
                        })
                    }
                })
            },
            _tabClose(i){
                this.searchList.splice(i,1);
                this._infoData();
            },
            _setData(i){
                this.baseList.position_list.map((res,index)=>{
                    if(res.PositionName == this.tableData[i].PositionName){
                        this.PositionName = index;
                    }
                });
                this.PositionRoleName = this.tableData[i].PositionRoleName;
                this.setId = this.tableData[i].Id;
                this.PositionNature= this.tableData[i].PositionNature;
                this.PositionType= this.tableData[i].PositionType;
                this.Education= this.tableData[i].Education;
                this.WorkExperience= this.tableData[i].WorkExperience;
                this.City= this.tableData[i].City;
                this.Description= this.tableData[i].Description;
                this.Remark=  this.tableData[i].Remark;
                this.ShowOnWechat= this.tableData[i].ShowOnWechat==1 ? true : false;
                this.ShowOnWeb= this.tableData[i].ShowOnWeb==1 ? true : false;
                this.ShowOnInterpolation= this.tableData[i].ShowOnInterpolation==1 ? true : false;
                this.InterpolationRuleId = this.tableData[i].InterpolationRuleId;
                this.Num= this.tableData[i].Num;
                this.SalaryMin= this.tableData[i].SalaryRange.split('-')[0];
                this.SalaryMax= this.tableData[i].SalaryRange.split('-')[1];
                this._addModel();
            },
            _del(i){
                this.$axios.post(8000069,{
                    Id: this.tableData[i].Id
                },res=>{
                    if(res.data.code==1){
                        this.tableData.splice(i,1);
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _infoData(){
                this.$message.loading("加载中...");
                let PositionNature = '',WorkExperience = '';
                this.searchList.map(res=>{
                    if(res.name=='职位性质'){
                        PositionNature = res.value;
                    }else if(res.name=='工作经验'){
                        WorkExperience = res.value;
                    }
                });
                this.$axios.get(8000070,{
                    PositionLevel: this.selectedKeys[0],
                    PositionNature: PositionNature,
                    WorkExperience: WorkExperience,
                    Page: 1,
                    PageSize: 10,
                },res=>{
                    this.$message.destroy();
                    this.tableData = res.data.data;
                })
            },
            _selectCity(value){
                this.City = value.join(',');
            },
            _addSave(){
                this.$message.destroy();
                if(this.PositionName==''){
                    this.$message.warning('职位名称必填');
                    return false;
                }
                if(this.PositionNature == ''){
                    this.$message.warning('职位性质必填');
                    return false;
                }
                if(this.WorkExperience == ''){
                    this.$message.warning('工作经验必填');
                    return false;
                }
                let item = {
                    Id: this.setId,
                    PositionId: this.baseList.position_list[this.PositionName].PositionId,
                    PositionName: this.baseList.position_list[this.PositionName].PositionName,
                    PositionRoleName: this.PositionRoleName,
                    PositionNature: this.PositionNature,
                    PositionType: this.PositionType,
                    Education: this.Education,
                    WorkExperience: this.WorkExperience,
                    City: this.City,
                    Description: this.Description,
                    Remark: this.Remark,
                    ShowOnWechat: this.ShowOnWechat? 1 : 0,
                    ShowOnWeb: this.ShowOnWeb? 1 : 0,
                    ShowOnInterpolation: this.ShowOnInterpolation? 1: 0,
                    InterpolationRuleId: this.InterpolationRuleId,
                    Num: this.Num,
                    SalaryMin: this.SalaryMin,
                    SalaryMax: this.SalaryMax
                };
                console.log(item);
                // this.$message.loading("保存中...");
                // this.$axios.post(8000068,item,res=>{
                //     this.$message.destroy();
                //     if(res.data.code==1){
                //         this.$message.success(res.data.msg);
                //         this._cancelVisible();
                //         this._infoData();
                //     }else{
                //         this.$message.error(res.data.msg)
                //     }
                // })
            },
            _cancelVisible(){
                this.setId = '';
                this.PositionName= '';
                this.PositionRoleName= '';
                this.PositionNature= '';
                this.PositionType= '';
                this.Education= '';
                this.WorkExperience= '';
                this.City= '';
                this.Description= '';
                this.Remark=  '';
                this.ShowOnWechat= false;
                this.ShowOnWeb= false;
                this.ShowOnInterpolation= false;
                this.InterpolationRuleId = '';
                this.Num= '';
                this.SalaryMin= '';
                this.SalaryMax= '';
                this.addVisible = false;
            },
            _treeData(){
                this.$axios.get(8000064,{},res=>{
                    this.treeData = res.data.data;
                    this.treeData.unshift({title: '全部',value: ''})
                })
            },
            _treeSelect(selectedKeys){
                this.selectedKeys = selectedKeys;
                this._infoData();
            },
            _addModel(){
                let self = this;
                this.addVisible = true;
                setTimeout(function () {
                    self.phoneEditor = new E('#websiteEditorElem')
                    self.phoneEditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor', // 文字颜色
                        'backColor', // 背景颜色
                        'list',
                        'table',
                        'link',
                        'justify',
                        'image'
                    ];
                    self.phoneEditor.customConfig.onchange = function (html) {
                        self.Description = html
                    };
                    // 创建一个富文本编辑器
                    self.phoneEditor.create();
                    self.phoneEditor.txt.html(self.Description)
                })
            },
            _closeVisible(){
                this.Position = '';
                this.EntryPrize = '';
                this.FullPrize = '';
                this.Rule = '';
                this.addRuleVisible = false;
            },
            _clickRow(record, index){
                let self = this;
                return {
                    on: {
                        click: () => {
                            console.log('点击了table列表')
                        }
                    }
                }
            },
            _showMOdelTest(type,e,list,txt){
                this.selectType = txt;
                let init = '';
                this.searchList.map(res=>{
                    if(res.name==txt){
                        init = res.value;
                    }
                });
                this.showMOdelTest.list = list;
                this.showMOdelTest.type = type;
                this.showMOdelTest.show = true;
                this.showMOdelTest.init = init;
                this.showMOdelTest.event = e;
            },
            _tableGetData(val){
                let isNew = true;
                this.searchList.map(res=>{
                    if(res.name==this.selectType){
                        res.value = val;
                        isNew = false;
                    }
                });
                if(isNew){
                    this.searchList.push({name: this.selectType,value: val})
                }
                this._infoData();
            },
        },
    }
</script>

<style scoped lang="less">
    .postStandard{
        height: 100%;
        border-radius: 5px;
        .container{
            align-items: normal;
            min-height: 100%;
        }
        .tree{
            width: 220px;
            margin-right: 10px;
            padding: 10px 5px 0;
            border-radius: 5px;
            min-height: 100%;
            background: #fff;
            .tree-name{
                padding: 0 5px 0;
            }
        }
        .content{
            flex: 1;
            padding-top: 10px;
            min-height: 100%;
            background: #fff;
            border-radius: 5px;
            .table{
                width: 98%;
                margin: 0 auto;
                .filter-icon{
                    margin-left: 5px;
                    color: #BDBDBD;
                    font-size: 14px;
                }
            }
            .tab-icon{
                font-size: 18px;
                color: #808080;
            }
        }
    }
    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button {
            width: 120px;
        }
    }
    .draw-content{
        flex: 1;
    }
    .draw-row{
        .text{
            color: #242424;
            font-size: 15px;
            span{
                margin-left: 5px;
                color: #FF0000;
            }
			label{
				margin-left: 185px;
			}
        }
        .input{
            height: 50px;
            padding-top: 5px;
        }
        .input-position{
            height: 50px;
            padding-top: 5px;
			display: inline;
			padding-right: 5px;
        }
        .nature-list{
            button{
                margin-right: 10px;
            }
        }
        .money-scope{
            display: flex;
            align-items: flex-end;
        }
    }
    .draw-title{
        margin-top: 30px;
        padding-bottom: 10px;
        color: #242424;
        font-size: 18px;
        border-bottom: 1px solid #E3E5E7;
    }
    .show-location{
        .row{
            >div{
                display: flex;
                align-items: center;
                width: 180px;
            }
            display: flex;
            align-items: center;
            padding: 18px 0;
            .label-name{
                display: inline-block;
                width: 90px;
                font-size: 15px;
                color: #242424;
            }
            .add-award-btn{
                margin-left: 15px;
                color: #3974FF;
                cursor: pointer;
            }
        }
    }
    .add-rule-content{
        .row{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .row-label{
                width: 100px;
                color: #575D6A;
                font-size: 15px;
            }
            .row-input{
                flex: 1;
                input{
                    width: 100%;
                }
                textarea{
                    width: 100%;
                    height: 70px;
                    resize: none;
                }
            }
        }
    }
</style>
