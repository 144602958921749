<template>
    <!--:style="{left:showMOdelTest.type=='date'?'-440px':'-220px'}"-->
    <div class="filterBox" v-show="showMOdelTest.show" :style="{left: left + 'px',top: event.y + event.target.scrollHeight+'px'}">
        <div class="selectTimeModel">
            <div class="sort">
                <p>升序A到Z</p>
                <p>降序Z到A</p>
            </div>
            <div class="filter-title">
                <img src="https://homework.sikegroup.com/filter-title-icon.png" alt=""> <span>筛选</span>
            </div>
            <!--日期筛选-->
            <div class="filter-content" v-if="showMOdelTest.type=='date'">
                <div class="content-title">选择日期</div>
                <div class="tags">
                    <a-button :type="dateIndex===item.date?'primary':''" v-for="(item,index) in selectDateList" @click.stop="_initDate(item.date)">{{item.name}}</a-button>
                    <div style="position: relative;display: inline-block;">
                        <a-button :type="dateIndex===-1?'primary':''">自定义</a-button>
                        <a-range-picker class="range-picker" @change="_customDate"/>
                    </div>
                </div>
            </div>

            <!--选择员工-->
            <div class="filter-content" v-if="showMOdelTest.type=='person'">
                <div class="content-title">人员</div>
                <person v-bind="personObj" @getData="readChangePerson"></person>
            </div>

            <!--内容搜素-->
            <div class="filter-content" v-if="showMOdelTest.type=='content'">
                <div class="content-title">内容</div>
                <a-input placeholder="请输入" v-model="selectContent"></a-input>
            </div>

            <!--状态筛选-->
            <div class="filter-content" v-if="showMOdelTest.type=='select'">
                <a-select style="width: 100%" v-model="selectValue" show-search>
                    <a-select-option :value="item.value" v-for="(item,index) in showMOdelTest.list" >{{item.name}}</a-select-option>
                </a-select>
            </div>
            <!--树选择-->
            <div class="filter-content"  v-if="showMOdelTest.type=='tree'">

                <a-tree-select  @change="changeTree" style="width: 100%" :tree-data="showMOdelTest.list" labelInValue treeNodeFilterProp="title" show-search />
                <!--<a-tree  style="width: 100%;background: #ffffff" :treeData="showMOdelTest.list" />-->
            </div>
            <!--级联选择器-->
            <div class="filter-content" v-if="showMOdelTest.type=='cascader'">
                <a-cascader v-model="cascaderValue" :options="showMOdelTest.list" style="width: 100%" placeholder="请选择" />
            </div>

            <div class="filter-footer">
                <a-button type="link" @click="_close">取消</a-button>
                <a-button type="primary" @click="_save">确定</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    /*
    * {
    *   list: [{name: '测试',value: '1'}], //选择下拉需要的数据
    *   type: 'date,person,content,select',//类型
    *   show: true,//控制显示隐藏
    *   init: '',初始化数据,
    *   event: '',//当前点击对象
    * }
    * */
    import person from "../../components/SelectPersonnel/SelectPersonnel"; //选人
    export default {
        name: "filterBox",
        components: {
            person
        },
        props: {
            showMOdelTest: {
                type: Object
            }
        },
        watch: {
            showMOdelTest:{
                handler:function(val,oldval){
                    this.readPerson =  val.init;
                    this.selectDate = val.init;
                    this.selectContent = val.init;
                    this.selectValue = val.init;
                    this.personObj.selectArr = val.init;
                    this.event = val.event;
                    this.cascaderValue = val.init || [];
                    if(document.documentElement.clientWidth - this.event.x > (val.type=='date'? 550 : 300)){
                        this.left = this.event.x;
                    }else{
                        if(val.type=='date'){
                            this.left = document.documentElement.clientWidth - 550;
                        }else{
                            this.left = document.documentElement.clientWidth - 300;
                        }
                    }

                    if(val.type=='date'){
                        if(val.init.length == 2){
                            this.dateIndex = -1;
                            return false;
                        }
                        if(val.init){
                            this.dateIndex = parseInt((new Date().getTime() - new Date(val.init).getTime()) / 86400000);
                        }
                    }
                },
                deep:true
            }
        },
        data(){
            return {
                init: '',
                selectDateList: [
                    {
                        name: '不限时间',
                        date: ''
                    },
                    {
                        name: '今天',
                        date: 0
                    },
                    {
                        name: '昨天',
                        date: 1
                    },
                    {
                        name: '过去7天',
                        date: 7
                    },
                    {
                        name: '过去30天',
                        date: 30
                    },
                ],
                personObj: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    contacts: "recent33", //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                dateIndex: '',

                readPerson: '',
                selectDate: '',
                selectContent: '',
                selectValue: '',
                cascaderValue: [],
                event: {
                    x: 0,
                    target: {
                        scrollHeight: 0
                    }
                },
                left: '',
                TreeData:'',
            }
        },
        mounted(){
            const self = this;
            // document.body.onclick = function(){
            //     self.showMOdelTest.show = false;
            // };
        },
        methods: {
            _save(){
                let data = '';
                if(this.showMOdelTest.type=='person'){
                    data = this.readPerson;
                }else if(this.showMOdelTest.type == 'select'){
                    data = this.selectValue;
                }else if(this.showMOdelTest.type == 'content') {
                    data = this.selectContent;
                }else if(this.showMOdelTest.type == 'tree'){
                    data = this.TreeData;
                }else if(this.showMOdelTest.type == 'cascader'){
                    data = this.cascaderValue;
                }else{
                    data = this.selectDate;
                }
                this.$emit('getData',data);
                this._close();
            },
            _close(){
                this.showMOdelTest.show = false;
                this.readPerson = '';
                this.selectDate = '';
                this.selectContent = '';
                this.selectValue = '';
                this.dateIndex = '';
                this.TreeData ='';
            },
            _customDate(date){
                this.dateIndex = -1;
                this.selectDate = [this.__moment__(date[0]).format('YYYY-MM-DD'),this.__moment__(date[1]).format('YYYY-MM-DD')];
            },
            changeTree(val){

                this.TreeData = val;
            },
            readChangePerson(value) {
                this.readPerson = value;
            },
            _initDate(date){
                let getTime = new Date().getTime();
                let upDate = getTime - 86400000  * date;
                let y,m,d;
                this.dateIndex = date;
                if(date || date===0){
                    y = new Date(upDate).getFullYear();
                    m = new Date(upDate).getMonth() < 10 ? '0'+(new Date(upDate).getMonth()+1): new Date(upDate).getMonth()+1;
                    d = new Date(upDate).getDate() < 10 ? '0'+new Date(upDate).getDate(): new Date(upDate).getDate();
                }else{
                    return '';
                }
                this.selectDate = y+'-'+m+'-'+d;
            }
        }
    }
</script>

<style scoped lang="less">
    .filterBox{
        position: fixed;
        z-index: 1000;
        color: #666;
        text-align: left;
        .selectTimeModel{
            min-width: 300px;
            padding: 10px;
            border-radius: 5px;
            background: #fff;
            box-shadow: 1px 1px 4px 1px #ddd;
        }
        .sort{
            text-align: left;
            padding-bottom: 2px;
            border-bottom: 1px solid #ddd;
            p{
                margin-bottom: 5px;
                cursor: pointer;
            }
        }
        .filter-title{
            padding: 5px 0;
            img{
                width: 18px;
                margin-right: 4px;
                vertical-align: sub;
            }
            span{

            }
        }
        .filter-content{
            padding: 10px;
            background: #F2F2F2;
            .tags{
                padding: 5px 0;
                white-space: nowrap;
                button{
                    margin-right: 8px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .filter-footer{
            margin-top: 10px;
            text-align: right;
        }
        .range-picker{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer
        }
    }
</style>
